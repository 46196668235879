import React, { useState } from 'react'
import { Table, TablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#f1f5f9',
            cursor: 'pointer',
        },
    },
}))

export default function useHeadlessTable(records, rowsPerPageOptions = [5]) {

    const classes = useStyles();

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0])


    const TblContainer = props => (
        <Table className={classes.table}>
            {props.children}
        </Table>
    )


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0);
    }

    const TblPagination = () => (<TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        count={records.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />)


    return {
        TblContainer,
        TblPagination
    }
}