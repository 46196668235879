import { makeStyles } from '@mui/styles';
import { amber, blue, lightBlue, grey, teal } from '@mui/material/colors';

export const useBaseStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    hover: {
        '&:hover': {
            // backgroundColor: '#cbd5e1',
            backgroundColor: '#f1f5f9',
        },
    },
    option: {
        color: grey[800],
        fontWeight: '400'
    },
    rounded: {
        borderRadius: theme.spacing(15),
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    left: {
        textAlign: 'left'
    },
    right: {
        textAlign: 'right'
    },
    middle: {
        textAlign: 'center',
    },
    secondary: {
        color: theme.palette.text.secondary
    },
    error: {
        color: theme.palette.error.main
    },
    base: {
        color: "white",
        margin: 0,
        borderRadius: 15,
        // textTransform: 'capitalize', // does have no effect on Typography
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    green_bg: {
        backgroundColor: "#00cc66",
    },
    teal_bg: {
        backgroundColor: teal[400],
    },
    amber_bg: {
        backgroundColor: amber[600],
    },
    grey_bg: {
        backgroundColor: grey[400],
    },
    blue_bg: {
        backgroundColor: blue[500]
    },
    lightBlue_bg: {
        backgroundColor: lightBlue[500]
    },
    red_bg: {
        backgroundColor: "#ff4d4d"
    },
    searchInput: {
        width: '90%'
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        overflowX: "auto"
    },
}));