import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { BillingService } from '../../services/BillingService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { CurrentPlan, BillingDetails, History } from '../../components/stripe';
import { PageHeader, Notification } from '../../components/common';
import notifyError from '../../errors/notifyError';
import { Box, CircularProgress } from '@mui/material';
import useAppContext from '../../hooks/useAppContext';

// const AccountSubscription = ({ subscription }) => {
//     return (
//         <section>
//             <hr />
//             <h4>
//                 <a href={`https://dashboard.stripe.com/test/subscriptions/${subscription.id}`}>
//                     {subscription.id}
//                 </a>
//             </h4>

//             <p>
//                 Status: {subscription.status}
//             </p>

//             <p>
//                 Card last4: {subscription.default_payment_method?.card?.last4}
//             </p>

//             <p>
//                 Current period end: {(new Date(subscription.current_period_end * 1000).toString())}
//             </p>

//             {/* <Link to={{pathname: '/change-plan', state: {subscription: subscription.id }}}>Change plan</Link><br /> */}
//             <Link to='/settings/cancel' state={{ subscriptionId: subscription.id }}>Cancel</Link>
//         </section>
//     )
// }

const Account = () => {
    //const [plan, setPlan] = useState('Free'); // does not work for state of type string
    const [price, setPrice] = useState({ plan: 'Free' });
    const [invoices, setInvoices] = useState([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [pendingEvent, setPendingEvent] = useState(null);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [isLoading, setIsLoading] = useState(true);
    const { store } = useAppContext();

    useEffect(() => {
        if (!store) {
            setNotify({
                isOpen: true,
                message: 'Please Select a Store',
                type: 'error'
            })
            return;
        }
        BillingService.getSubscription(axiosPrivate)
            .then(response => {
                const { subscription, defaultPaymentMethod } = response.data;
                if (subscription) {
                    const { subscription_id, plan, price, pending_event } = subscription;
                    setPrice({ ...price, plan });
                    setPendingEvent(pending_event);
                    setSubscriptionId(subscription_id);
                }
                setDefaultPaymentMethod(defaultPaymentMethod);
                setInvoices(response.data.invoices);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                notifyError(err, setNotify, navigate, location);
            });
    }, [axiosPrivate, navigate, location, store]);

    return (
        <>
            <PageHeader
                title="Settings"
                subTitle="Billing"
            />
            {isLoading ?
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <CircularProgress sx={{
                        marginTop: 10,
                        marginBottom: 10,
                        mx: 'auto'
                    }} />
                </Box> :

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 600,
                    overflowX: "auto"
                }}>
                    <CurrentPlan price={price} subscriptionId={subscriptionId} pendingEvent={pendingEvent} />
                    {defaultPaymentMethod && <BillingDetails defaultPaymentMethod={defaultPaymentMethod} />}
                    {invoices.length !== 0 && <History invoices={invoices} />}
                </Box>

            }


            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    );
}

export default Account;
