import React, { useState, useEffect } from 'react';
import StoreService from "../../services/StoreService";

import { PageHeader, Notification } from '../../components/common';
import { Paper, Grid, TextField } from '@mui/material';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAppContext from '../../hooks/useAppContext';
import notifyError from '../../errors/notifyError';

import { useStyles } from '../styles';

const General = () => {
    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();
    const [email, setEmail] = useState('');
    const [addr, setAddr] = useState('');

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const navigate = useNavigate();
    const location = useLocation();
    const { store } = useAppContext();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        if (!store) {
            setNotify({
                isOpen: true,
                message: 'Please Select a Store',
                type: 'error'
            })
            return;
        }
        StoreService.getStoreContact(axiosPrivate, store._id)
            .then(response => {
                console.log(response.data);
                isMounted && setEmail(response.data.email);
                isMounted && setAddr(response.data.addr);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, setEmail, store]);

    return (
        <>
            <PageHeader
                title="Settings"
                subTitle="General"
            />
            <Paper className={classes.pageContent}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            type='text'
                            label="Email Notification"
                            variant="outlined"
                            size="small"
                            name='email'
                            value={email || ''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            disabled
                            type='text'
                            label="Company Address"
                            variant="outlined"
                            size="small"
                            name='email'
                            value={addr || ''}
                        />
                    </Grid>
                </Grid>

            </Paper>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>);
};



export default General;
