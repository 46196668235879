import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    pageContent: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
        padding: theme.spacing(3),
        overflowX: "auto"
    },
    gridInsidePaper: {
        padding: theme.spacing(2),
    },

    defaultCard: {
        backgroundColor: '#0040ff',
    },
}))