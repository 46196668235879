import React from 'react';
import { useForm, Form } from '../common/useForm';
import { TextInput, RadioGroup, Button } from '../common/inputs';
import { Box, Grid } from '@mui/material';

const initUser = {
    _id: 0,
    userId: '',
    userName: '',
    email: '',
    role: 'user',
    storeId: ''
}

const roleItems = [
    { id: 1, value: 'user', label: 'User' },
    { id: 2, value: 'admin', label: 'Admin' },
    { id: 3, value: 'owner', label: 'Owner', disabled: true },
]


export default function UserForm(props) {
    const { addOrEdit, recordForEdit } = props;
    const resetUser = { ...initUser };
    if (recordForEdit) {
        resetUser._id = recordForEdit._id;
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('userName' in fieldValues)
            temp.userName = fieldValues.userName ? "" : "This field is required."
        if ('email' in fieldValues)
            temp.email = fieldValues.email ? ((/.+@.+\..+/).test(fieldValues.email) ? "" : "Email is not valid.") : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            console.log("validate", temp);
            return Object.values(temp).every(x => x === "")
        }
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(recordForEdit || initUser, resetUser, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column"
                sx={{
                    mx: 'auto',
                    minWidth: 200,
                    maxWidth: 400,
                    width: '100%',
                    display: "column"
                }}>

                <TextInput
                    type="text"
                    label="Full Name"
                    name="userName"
                    value={values.userName}
                    onChange={handleInputChange}
                    error={errors.name} />

                <TextInput
                    disabled={recordForEdit}
                    type="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email} />

                <RadioGroup
                    label="User Roles"
                    name="role"
                    value={values.role}
                    onChange={handleInputChange}
                    items={roleItems}
                />

                <Box display="flex" justifyContent="flex-end">
                    <Button
                        type="submit"
                        text="Submit" />
                    <Button
                        text="Reset"
                        color="grey"
                        onClick={resetForm} />
                </Box>
            </Box >
        </Form >
    )
}


