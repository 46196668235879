import React, { useState, useEffect, useReducer } from 'react';
import { useForm, Form } from '../../common/components/common/useForm';
import { Button } from '../../common/components/common/inputs';
import { TextField, Box, MenuItem, Typography } from '@mui/material';

import { CanadaStates, USStates, Countries, ZipPatterns } from '../../common/data';
import CountrySelect from '../../common/components/common/CountrySelect';
import { reducer } from '../../common/components/common/reducer';
import { defaultState, closeModal } from './RuleUtil';
import Modal from '../../common/components/common/Modal';

const initAddr = {
    _id: 0,
    country: 'United States',
    name: '',
    street_1: '',
    city: '',
    state: '',
    zip: ''
}

export default function AddrForm(props) {
    const { addOrEdit, recordForEdit } = props;
    const resetAddr = { ...initAddr };
    if (recordForEdit) {
        resetAddr._id = recordForEdit._id;
    }
    const [state, dispatch] = useReducer(reducer, defaultState);

    const [zipPattern, setZipPattern] = useState({ placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" });
    const [zipDisplay, setZipDisplay] = useState({ disabled: false, backgroundColor: 'white' });
    const [states, setStates] = useState(USStates);
    const [isUSCanada, setIsUSCanada] = useState(true);

    const {
        values,
        setValues,
        handleInputChange,
        resetForm
    } = useForm(recordForEdit || initAddr, resetAddr, false);

    const [country, setCountry] = useState(Countries[230]); // displayed in the input

    useEffect(() => {
        const foundCountry = Countries.find(country => country.label === values.country);
        setCountry(foundCountry); // displayed in the input
        checkStateZipPatternChanges(foundCountry.label);
    }, []);

    //*****NOTE*****
    //point: To respond to reset button,  useEffect with values dependency is slow,
    // Moreover, it works only when adding following code to the handleCountryChange function
    // setValues({ ...values, country });
    // (change the country part of the values to trigger values to be stored in a different address,
    //so when clicking on reset button to setValues, the above useEffect condition can be fired surely)
    //The reason is it will be called whenever the storage address of values changes, 
    // due to monitored in the event loop

    const handleCountryChange = (newCountry) => {
        setCountry(newCountry);
        checkStateZipPatternChanges(newCountry.label);
    };

    const handleReset = () => {
        resetForm();
        handleCountryChange(Countries[230]);
    }

    const checkStateZipPatternChanges = (country) => {
        if (country === 'United States') {
            setStates(USStates);
            setIsUSCanada(true);
        } else if (country === 'Canada') {
            setStates(CanadaStates);
            setIsUSCanada(true);
        } else {
            setIsUSCanada(false);
        }
        const zipCode = ZipPatterns.get(country);
        setZipPattern(zipCode);
        if (zipCode.pattern === '') {
            setZipDisplay({ disabled: true, backgroundColor: '#D3D3D3' });
        } else {
            setZipDisplay({ disabled: false, backgroundColor: 'white' });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (country && values.city && values.state) {
            if (zipDisplay.disabled === false && !values.zip) {
                dispatch({ type: 'NO_VALUE' });
            }
            addOrEdit({ ...values, country: country.label }, resetForm);
        } else {
            dispatch({ type: 'NO_VALUE' });
        }
    };

    const handleCountrySubmit = async (e) => {
        e.preventDefault();
        if (country) {
            const countryAddr = { ...values, country: country.label, name: '', street_1: '', city: '', state: '', zip: '' }
            addOrEdit(countryAddr, resetForm);
        } else {
            dispatch({ type: 'NO_VALUE' });
        }
    };

    return (
        <>
            {state.isModalOpen && <Modal closeModal={() => closeModal(dispatch)} modalContent={state.modalContent} />}

            <Form onSubmit={handleSubmit}>
                <Box sx={{
                    mx: 'auto',
                    display: "flex",
                    flexDirection: "row",
                    width: '100%',
                    overflow: "auto"
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>

                    <Typography color="error" style={{ fontSize: 23, width: '2%' }} >*</Typography>
                    <CountrySelect country={country} handleCountryChange={handleCountryChange} />

                    <Button
                        text="redflag country"
                        color="primary"
                        size="medium"
                        onClick={handleCountrySubmit}
                    />

                </Box>

                <Box sx={{
                    mx: 'auto',
                    display: "flex",
                    flexDirection: "row",
                    width: '100%',
                    overflow: "auto"
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>
                    <Typography color="white" style={{ fontSize: 23, width: '2%' }} >{"*"}</Typography>
                    <TextField
                        type='text'
                        label="Name"
                        variant="outlined"
                        size="small"
                        name="name"
                        value={values.name || ''}
                        placeholder="John Doe"
                        onChange={handleInputChange}
                        sx={{
                            width: '98%'
                        }}
                    />
                </Box>
                <Box sx={{
                    mx: 'auto',
                    display: "flex",
                    flexDirection: "row",
                    width: '100%',
                    overflow: "auto"
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>
                    <Typography color="white" style={{ fontSize: 23, width: '2%' }} >{"*"}</Typography>
                    <TextField
                        type='text'
                        label="No typing to block all adresses under the City or Zip"
                        variant="outlined"
                        size="small"
                        name="street_1"
                        value={values.street_1 || ''}
                        placeholder="Street Address or P.O. Box"
                        onChange={handleInputChange}
                        sx={{
                            width: '98%'
                        }}
                    />
                </Box>
                <Box sx={{
                    mx: 'auto',
                    display: "flex",
                    flexDirection: "row",
                    width: '100%',
                    overflow: "auto"
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>
                    <Typography color="error" style={{ fontSize: 23, width: '2%' }} >*</Typography>
                    <TextField
                        type='text'
                        label="City"
                        variant="outlined"
                        size="small"
                        name="city"
                        value={values.city || ''}
                        onChange={handleInputChange}
                        sx={{
                            mr: 2,
                            width: '32.6%'
                        }}
                        required
                    />
                    <Typography color="error" style={{ fontSize: 23 }}>*</Typography>
                    {isUSCanada ?
                        <TextField
                            required
                            label="State"
                            variant="outlined"
                            size="small"
                            name="state"
                            value={values.state || ''}
                            placeholder="State"
                            onChange={handleInputChange}
                            select
                            sx={{
                                mr: 2,
                                width: '32%'
                            }}
                        >
                            {
                                states.map((state, index) => {
                                    return (<MenuItem key={index} value={state}>{state}</MenuItem>)
                                })
                            }
                        </TextField> :
                        <TextField
                            required
                            type='text'
                            label="State"
                            variant="outlined"
                            size="small"
                            name="state"
                            value={values.state || ''}
                            placeholder="State"
                            onChange={handleInputChange}
                            sx={{
                                mr: 2,
                                width: '32%'
                            }}
                        />
                    }
                    <Typography color="error" style={{ fontSize: 23, display: zipDisplay.disabled ? 'none' : 'inline' }}>*</Typography>
                    <TextField
                        type='text'
                        label="Zip"
                        disabled={zipDisplay.disabled}
                        variant="outlined"
                        size="small"
                        name="zip"
                        value={values.zip || ''}
                        placeholder={zipPattern.placeholder}
                        onChange={handleInputChange}
                        inputProps={{ inputMode: 'numeric', pattern: zipPattern.pattern }}
                        sx={{
                            width: '32%',
                            backgroundColor: zipDisplay.backgroundColor
                        }}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        type="submit"
                        text="Submit" />
                    <Button
                        text="Reset"
                        color="grey"
                        onClick={handleReset} />
                </Box>
            </Form>
        </>
    )
}


