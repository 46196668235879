import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';
import { TextField, Button, Typography, Grid } from '@mui/material';
import useAppContext from '../../hooks/useAppContext';
import { makeStyles } from '@mui/styles';
import { setLogin } from '../../browser/localStorage';
import displayError from '../../errors/displayError';
import ResendOtp from './ResendOtp';
import SelectStore from "./SelectStore";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    middle: {
        textAlign: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const VerifyDeviceOtp = ({ email }) => {
    const { setAuth, setStore } = useAppContext();
    const classes = useStyles();
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isTimeout, setIsTimeout] = useState(false);
    const [isSelectingStore, setIsSelectingStore] = useState(false);
    const [storeUsers, setStoreUsers] = useState([]);
    const [refreshToken, setRefreshToken] = useState(null);

    useEffect(() => {
        setErrMsg('');
    }, [otp]);

    const handleResend = async (e) => {
        e.preventDefault();
        if (!email) return;

        try {
            await AuthService.resendOtp({ email, isDevice: true });
            setIsTimeout(false);
            setTimeout(() => setIsTimeout(true), 60000); //60 seconds
        }
        catch (err) {
            displayError(err, setErrMsg);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !otp) return;

        try {
            const { data } = await AuthService.verifyDeviceOtp({ email, otp });
            if (data.msg === 'Please select one store') {
                setAuth({});
                setIsSelectingStore(true);
                const { refreshToken, storeUsers } = data;
                setRefreshToken(refreshToken);
                setStoreUsers(storeUsers);
            } else {
                const { store, auth } = data;
                setAuth(auth);
                setStore(store);
                setLogin();
                navigate(process.env.REACT_APP_ENTRY_ROUTE);
            }
        }
        catch (err) {
            displayError(err, setErrMsg);
        }
    };


    return (
        isSelectingStore ?
            <SelectStore email={email} refreshToken={refreshToken} storeUsers={storeUsers} /> :
            <>
                <p className={errMsg ? "alert-danger login-container" : "offscreen login-container"} aria-live="assertive" >{errMsg}</p >
                <h1>Verification required</h1>
                <form className="form login-container" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    width: '100%',
                                }}
                            >
                                To continue, complete this verification step.
                                We've sent a One Time Passcode (OTP) to the email <b>{email}</b>. Please enter it below within 10 minutes of receiving it.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                type='text'
                                label="Enter OTP"
                                variant="outlined"
                                size="small"
                                value={otp}
                                sx={{
                                    width: '100%',
                                }}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.middle}>
                            <Button
                                type='submit'
                                variant="contained"
                                sx={{
                                    width: '100%'
                                }}>
                                Continue
                            </Button>
                        </Grid>
                        <ResendOtp isTimeout={isTimeout} setIsTimeout={setIsTimeout} handleResend={handleResend} />
                    </Grid>

                </form>
            </>
    );
};


export default VerifyDeviceOtp;
